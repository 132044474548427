import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import PageHeading from '../../components/pageheading';
import Product from '../../components/product';
import Customers from '../../components/customers';
import CallToAction from '../../components/calltoaction';
import Testimonials from '../../components/testimonials';
import img from '../../images/statistics@2x.png';
import anna from '../../images/persons/anna_lindberg.jpg';
import statsImg1 from '../../images/content/stats1.png';
import statsImg2 from '../../images/content/stats2.png';
import statsImg3 from '../../images/content/computer.jpg';

const SalesDistricts = props => {
    return (
        <Layout>
            <Helmet>
                <title>Delfi.se - Statistik för dagligvarubranschen</title>
            </Helmet>
            <article>
                <PageHeading heading="Statistik för dagligvarubranschen" 
                    preamble="Hur förändras totalmarknaden för dina produkter? Vilka segment tar andelar och vilka backar? Vilka förändringar är tillfälliga och vilka bildar ett mönster eller en trend?" 
                    img={img} />
                <Product modifiers={{
                    'product--gray': true
                }} 
                heading="Delfi Statistik"
                callToAction="Boka ett demomöte" 
                img={statsImg1}
                textLeft={true}>
                    <p>
                        För att leverantörerna ska kunna följa utvecklingen på sina marknader erbjuder Delfi Marknadspartner försäljningsstatistik i olika former. Vi mäter närmare 250 segment från 200 företag inom marknader som till exempel bryggeri, vin, choklad och konfektyr men även allmän branschutveckling för såväl foodservice- som för retail.
                    </p>
                    <Link to="/demo" className="button button--large">Kontakta oss</Link>
                </Product>

                <Product heading="Vår statistiklösning"
                         img={statsImg2}>
                    <p>
                        Vår statistik är en klassisk branschstatistik – den bygger på att företagen inom ett varuområde rapporterar in sin försäljning till oss via vår webbaserade statistikplattform, därifrån sammanställs och presenteras rapporter i form av tabeller och diagram och innehåller information om både totalmarknaden och det enskilda företaget, med historik och andelar.
                    </p>
                    <Link to="/demo" className="button button--large">Kontakta oss</Link>
                </Product>

                <Product heading="Konfidentialitet, kunskap och trygghet"
                         textLeft={true} 
                         img={statsImg3}>
                    <p>
                        Delfi är en neutral och oberoende part med lång erfarenhet av att hantera statistik från dagligvarubranschen, vilket innebär att vi behandlar alla uppgifter med högsta konfidentialitet. Det är en trygghet för dig och en av många andra fördelar med Delfi Statistik.
                    </p>
                    <Link to="/demo" className="button button--large">Kontakta oss</Link>
                </Product>

                <Testimonials statsNumber="250"
                              statsText="segment från 200 företag inom marknader som bryggeri, vin, choklad och konfektyr."
                              quote="Vår statistiklösning erbjuder många fördelar. Hög tillförlitlighet, prisvärd statistik med hög täckningsgrad. Vårt sätt att mäta gör det möjligt att få en överblick av samtliga försäljningskanaler, även för varukategorier med väldigt spridd distribution. Delfi är en neutral part med lång erfarenhet av att hantera branschstatistik." 
                              quoteAuthor="Anna Lindberg" 
                              quoteAuthorTitle="Delfi Statistik"
                              quoteAuthorImg={anna} />

                <CallToAction heading="Vill du veta mer om statistik för dagligvarubranschen?">
                    <Link to="/demo" className="button button--large">Kontakta oss</Link>
                </CallToAction>

                <Customers heading="Vi har levererat information till branschen sedan 1987" />
            </article>
        </Layout>
    );
};

export default SalesDistricts;
